import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'
import {QRCodeSVG} from 'qrcode.react'
const QRScanner = ({value, title}) => {
  function downloadSVG(containerId, fileName = 'download.svg', padding = 10) {
    const container = document.getElementById(containerId)
    if (!container) {
      console.error('Container not found')
      return
    }

    const svgElement = container.querySelector('svg')
    if (!svgElement) {
      console.error('No SVG found inside the container')
      return
    }

    // Get the SVG's original width and height
    const svgWidth: any = svgElement.getAttribute('width')
    const svgHeight: any = svgElement.getAttribute('height')

    // Add padding around the SVG content
    const newWidth = parseInt(svgWidth) + padding * 2
    const newHeight = parseInt(svgHeight) + padding * 2

    // Create a new SVG container with padding
    const newSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${newWidth}" height="${newHeight}" viewBox="-${padding} -${padding} ${newWidth} ${newHeight}">
        ${svgElement.outerHTML}
    </svg>`

    const blob = new Blob([newSvg], {type: 'image/svg+xml'})
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    URL.revokeObjectURL(url)
  }

  return (
    <div style={{textAlign: 'center'}}>
      <button
        onClick={() => downloadSVG(value, `qrcode${title}.svg`)}
        className='btn btn-sm btn-light-success'
      >
        Download SVG
      </button>
      <div style={{height: 'auto', margin: '0 auto', maxWidth: 256}}>
        <h3 className='p-5'>{title}</h3>
        <div id={value}>
          <QRCodeSVG size={256} value={value} />
        </div>
      </div>
    </div>
  )
}

export default QRScanner
