/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { TableOnes } from '../../../../app/pages/js/TableOne'
import { getRequest, patchRequest } from '../../../../app/pages/Helpers/Requests'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import './../../../assets/css/custom.css'
type Props = {
  className: String
  tableone: {}
  stationId: any
}
export interface tableoneUpdate {
  config: String
  value: String
  description: String
  access: String
}

export const Tableone = {
  config: 'Configuration Name',
  value: 'Configuration Value',
  description: 'Configuration Description',
  access: 'Accessibility',
}

const StationSettingsTable: React.FC<Props> = ({ className, stationId }) => {
  const [configuration, setConfiguration] = useState([])
  const { pointId } = useParams()

  const getAllLogConfigurationByStation = async () => {
    try {
      const res = await getRequest('/log-config/' + pointId)
      if (res?.status === 200) {
        setConfiguration(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const changeConfiguration = async (id, itemValue) => {
    const loadingToast = toast.loading('Changing the configuration')
    try {
      const res = await patchRequest('/log-config/change-status/' + id, {
        value: itemValue == 1 ? 0 : 1,
      })
      if (res?.status === 200) {
        toast.success('Successfully Updated the value', {
          id: loadingToast,
        })
        getAllLogConfigurationByStation()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.message, {
        id: loadingToast,
      })
    }
  }
  const changeStorageValue = async (id, itemValue) => {
    const loadingToast = toast.loading('Changing the configuration')
    try {
      const res = await patchRequest('/log-config/change-store-status/' + id, {
        storeValue: itemValue == 1 ? 0 : 1,
      })
      if (res?.status === 200) {
        toast.success('Successfully Updated the value', {
          id: loadingToast,
        })
        getAllLogConfigurationByStation()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.message, {
        id: loadingToast,
      })
    }
  }
  const changeDisplayStatus = async (id, itemValue) => {
    const loadingToast = toast.loading('Changing the configuration')
    try {
      const res = await patchRequest('/log-config/change-display-status/' + id, {
        displayValue: itemValue == 1 ? 0 : 1,
      })
      if (res?.status === 200) {
        toast.success('Successfully Updated the value', {
          id: loadingToast,
        })
        getAllLogConfigurationByStation()
      }
    } catch (error: any) {
      console.log(error)
      toast.error(error.message, {
        id: loadingToast,
      })
    }
  }

  useEffect(() => {
    if (pointId !== null && pointId !== '' && pointId !== undefined) {
      getAllLogConfigurationByStation()
    }
  }, [pointId])

  return (
    <div className={`card ${className}`}>
      <div className='d-flex justify-content-center text-center'>
        <h1 className='fs-1 ms-5 mt-3 text-info'>OCPP LOG SETTINGS</h1>
        <span className="badge fs-6 badge-light-danger py-2 px-3">(developer options)</span>
        <div className='separator mb-4'></div>
      </div>
      <div className='table-responsive'>
        <table className='table table-row-dashed table-striped gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-black border-bottom-2 border-gray-200'>
              <th className='min-w-110px'>Log Type</th>
              <th className='max-w-110px'>Storing Log</th>
              <th className='max-w-110px'>Store</th>
              <th className='max-w-110px'>Display Log</th>
              <th className='max-w-110px'>Display</th>
            </tr>
          </thead>
          <tbody>
            {configuration?.map((item: any, index: any) => {
              return (
                <tr key={index}>
                  <td>{item?.key}</td>
                  <td>
                    <span
                      className={
                        item?.storeValue === 1
                          ? 'badge fs-7 fw-semibold badge-light-success'
                          : 'badge fs-7 fw-semibold badge-light-danger'
                      }
                    >
                      {item?.storeValue === 1 ? 'Enabled' : 'Disabled'}
                    </span>
                  </td>

                  <td>
                    <div className='menu-item'>
                      <div className='menu-content'>
                        <label className='form-check form-switch form-check-custom success'>
                          <input
                            className='form-check-input w-30px h-20px'
                            type='checkbox'
                            value={item?.storeValue}
                            checked={item?.storeValue === 1 ? true : false}
                            name='configurations'
                            onChange={async () => {
                              await changeStorageValue(item?.id, item?.storeValue)
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      className={
                        item?.displayValue === 1
                          ? 'badge fs-7 fw-semibold badge-light-success'
                          : 'badge fs-7 fw-semibold badge-light-danger'
                      }
                    >
                      {item?.displayValue === 1 ? 'Enabled' : 'Disabled'}
                    </span>
                  </td>
                  <td>
                    <div className='menu-item'>
                      <div className='menu-content'>
                        <label className='form-check form-switch form-check-custom success'>
                          <input
                            className='form-check-input w-30px h-20px input2'
                            type='checkbox'
                            value={item?.displayValue}
                            checked={item?.displayValue === 1 ? true : false}
                            name='configurations'
                            onChange={async () => {
                              await changeDisplayStatus(item?.id, item?.displayValue)
                            }}

                          />
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export { StationSettingsTable }
