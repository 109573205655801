import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import QRScanner from '../../UI/QRScanner'

const StationQrCode = ({connectors, stationId}) => {
  console.log(connectors)
  return (
    <div>
      <div className='card card-custom mt-15'>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-50px w-50px bg-light me-5'>
              <img src={toAbsoluteUrl('/qr-code-svg.svg')} alt='Metronic' className='p-3' />
            </div>

            <a href='#' className='fs-1 fw-bold  text-dark m-0'>
              Qr Codes of Station
            </a>
          </div>

          <div className='card-toolbar m-0'></div>
        </div>
        <div className='card-body'>
          <div className='row'>
            {connectors.map((connector: any) => {
              return (
                <div className='col-md-6' key={connector.id}>
                  <h1 className='mb-5'>Connector {connector.connector_ID}</h1>
                  <div className='mb-10'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                      <label
                        className='form-check-label d-flex align-items-center fs-5  text-dark'
                        htmlFor='Restrooms'
                      >
                        <QRScanner
                          value={`https://web.zect.in?stationId=${stationId}&connectorId=${connector.connector_ID}`}
                          //   {
                          //     connectorId: `${connector.connector_ID}`,
                          //     chargeboxId: `${stationId}`,
                          // }
                          title={'ChargeBox Id :' + stationId}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

// const StationQrCode = ({ connectors, stationId }) => {
//   const qrCodeRefs = useRef<any[]>([]);

//   const downloadQRCode = (index: number) => {
//     const svg = qrCodeRefs.current[index]?.querySelector('svg');
//     if (svg) {
//       const svgData = new XMLSerializer().serializeToString(svg);
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//       const img = new Image();
//       img.onload = () => {
//         const scale = 4; // Increase the scale for higher resolution
//         canvas.width = img.width * scale;
//         canvas.height = img.height * scale;
//         ctx?.scale(scale, scale);
//         ctx?.drawImage(img, 0, 0);
//         const pngUrl = canvas
//           .toDataURL('image/png')
//           .replace('image/png', 'image/octet-stream');
//         const downloadLink = document.createElement('a');
//         downloadLink.href = pngUrl;
//         downloadLink.download = `QRCode_Station_${stationId}_Connector_${connectors[index].connector_ID}.png`;
//         document.body.appendChild(downloadLink);
//         downloadLink.click();
//         document.body.removeChild(downloadLink);
//       };
//       img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
//     } else {
//       console.error('SVG not found');
//     }
//   };

//   return (
//     <div>
//       <div className='card card-custom mt-15'>
//         <div className='card-header flex-nowrap border-0 pt-9'>
//           <div className='card-title m-0'>
//             <div className='symbol symbol-50px w-50px bg-light me-5'>
//               <img src={toAbsoluteUrl('/qr-code-svg.svg')} alt='Metronic' className='p-3' />
//             </div>
//             <a href='#' className='fs-1 fw-bold text-dark m-0'>
//               Qr Codes of Station
//             </a>
//           </div>
//           <div className='card-toolbar m-0'></div>
//         </div>
//         <div className='card-body'>
//           <div className='row'>
//             {connectors.map((connector: any, index: number) => (
//               <div className='col-md-6' key={connector.connector_ID}>
//                 <h1 className='mb-5'>
//                   Connector {connector.connector_ID}
//                   <button
//                     onClick={() => downloadQRCode(index)}
//                     className='ms-5 btn btn-outline-primary btn-sm'
//                   >
//                     <i className='ki-outline ki-arrow-down fs-2 text-success' />
//                   </button>
//                 </h1>
//                 <div className='mb-10'>
//                   <div className='form-check form-check-custom form-check-solid form-check-sm'>
//                     <label
//                       className='form-check-label d-flex align-items-center fs-5 text-dark'
//                       htmlFor='Restrooms'
//                     >
//                       <div ref={(el) => (qrCodeRefs.current[index] = el)} title={'ChargeBox Id :' + stationId}>
//                         <QRCode
//                           value={`https://web.zect.in?stationId=${stationId}&connectorId=${connector.connector_ID}`}
//                         />
//                       </div>
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StationQrCode;

export default StationQrCode
