/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils';
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider';
import { getRequest } from '../../../../app/pages/Helpers/Requests';

type Props = {
  className: string;
  stationId?:any
};

export const Chart = {
  heading: 'Daily Energy Consumption',
};

function getDayOfWeek(dateStr: string) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const date = new Date(dateStr);
  const dayOfWeek = daysOfWeek[date.getDay()];
  return dayOfWeek;
}

const ChartsWidget7: React.FC<Props> = ({ className,stationId }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  // const [stationId, setStationId] = useState("ZECTAP0001");
  const [days, setDays] = useState<string[]>([]);
  const [consumption, setConsumption] = useState<number[]>([]);

  const { mode } = useThemeMode();

  useEffect(() => {
    getDayWiseConsumption();
  }, [stationId]);

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, consumption, days]);

  const getDayWiseConsumption = async () => {
    try {
      const res = await getRequest('/chargebox/past-week-consumption/' + stationId);
      console.log(res);
      if (res?.status === 200) {
        const days = res.data.data.map((item: any) => getDayOfWeek(item.date));
        const consumed = res.data.data.map((item: any) => item.consumption/1000);
        setDays(days);
        setConsumption(consumed);

        console.log(days);
        console.log(consumed);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function refreshChart() {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));
    const chartOptions = getChartOptions(height, consumption, days);

    const chart = new ApexCharts(chartRef.current, chartOptions);
    if (chart) {
      chart.render();
    }

    return chart;
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{Chart.heading}</span>

          {/* <span className='text-muted fw-semibold fs-7'>More than 500 energies</span> */}
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id='kt_charts_widget_8_chart'
          style={{ height: '350px' }}
          className='card-rounded-bottom'
        ></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

function getChartOptions(height: number, consumption: number[], days: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');
  const baseColor = getCSSVariableValue('--bs-primary');
  const baseLightColor = getCSSVariableValue('--bs-primary-light');
  const secondaryColor = getCSSVariableValue('--bs-info');

  return {
    series: [
      {
        name: 'Consumption',
        type: 'bar',
        data: consumption,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      stacked: true,
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '12%',
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: days,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      max: 1000,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return   val + ' KWh';
        },
      },
    },
    colors: [baseColor, secondaryColor, baseLightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };
}

export {ChartsWidget7};
